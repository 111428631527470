import * as React from "react";

import Layout from "../../components/Layout";
import StepByStepRoll from "../../components/StepByStepRoll";

export default class StepByStepIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <div
          className="full-width-image-container margin-top-0"
        >
        </div>
        <section className="section">
          <div className="container">
            <div className="content">
              <StepByStepRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
